import React from 'react'
import { Container, Row, Col, Carousel,Button } from 'react-bootstrap';
import testimonial from '../../../../Images/39CF5948819D86F584AF9A0215_1581147155515.png'


const Tesimonial = () => {
    const carouselItems = [
        {
          id: 1,
          label: 'Testimonial 1',
          text: 'Nulla vitae elit libero, a pharetra augue mollis interdum.',
        },
        {
          id: 2,
          label: 'Testimonial 2',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        },
        {
          id: 3,
          label: 'Testimonial 3',
          text: 'Praesent commodo cursus magna, vel scelerisque nisl consectetur.',
        },
      ];
    
 
  return (
   <>
    <Container fluid>
      <div className='mt-100 '>
      <h1 className='text-center mb-5 TitleColor'>Testimonial</h1>
      <Carousel
        interval={null}
               >
        {carouselItems.map((item) => (
          <Carousel.Item key={item.id}>
            <div className="testimonial-overlay">
              {/* <img
                className="d-block img-fluid w-100 h-100"
                src=""
                alt=""
              /> */}
            </div>
            <Carousel.Caption className="text-center d-flex flex-column align-items-center gap-2">
              <h3>{item.label}</h3>
              <p>{item.text}</p>
              <Button className='btn btnOrange'>Read More</Button>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
      </div>
    </Container>
   </>
  )
}

export default Tesimonial
