import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import image1 from '../../../../Images/service-1.webp';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import style from './Services.module.css';
import { useNavigate } from 'react-router-dom';

const Services = () => {
  const [serviceList, setServiceList] = useState([]);
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    message: '',
    mobile: '',
    product_id: 1,
    client_id: 1,
    title: '',


  });
  const handleSubmit = async () => {
    setSaving(true);
    if (!saving) {
      handleClose();
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/sendinquiry`, {
          email_id: formData.email,
          mobile: formData.mobile,
          dissociation: formData.message,
          product_id: formData.product_id,
          client_id: formData.client_id,
          title: formData.title,
        });


        console.log('Response:', response.data);


        handleClose();
        setFormData({
          email: '',
          message: '',
          mobile: '',
          product_id: 1,
          client_id: 1,
          title: '',
        });
      } catch (error) {
        console.error('Error:', error);

      } finally {
        setSaving(false);
      }
    }
  };

  function truncateText(text, maxLength) {
    if (!text || text.length <= maxLength) {
      return text;
    }
    const truncatedText = text.substring(0, maxLength).trim();
    return `${truncatedText}...`;
  }


  useEffect(() => {
    fetchServiceList();
  }, []);

  const fetchServiceList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/home`);
      setServiceList(response.data.ResponseData.serviceList);
    } catch (error) {
      console.error('Error fetching service list:', error);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleEnquiry = (id) => {
   
    navigate(`/enquirypage/${id}`);
  };
  return (
    <>
    <section id='services'>
  <Container fluid className='pe-xxl-5 ps-xxl-5'>
  <div className='mt-100'>
      {serviceList.map((menu) => (
        <div key={menu.id}>
          <h1 className='text-center mb-5 TitleColor'>{menu.menu_name}</h1>
          <Row>
            {menu.menu_product.map((service) => (
              <Col md={4} lg={4} xl={2} xxl={2} xs={6} key={service.id}>
                <div className={style.serviceCard}>
                  <div className={style.serviceImage}>
                    <img src={service.image} alt="" className=' rounded-4' onClick={() => handleEnquiry(service.id)} />
                  </div>                
                  <h5 onClick={() => handleEnquiry(service.id)}>{service.service_name.slice(0, 20)}</h5>                                
                  <p onClick={() => handleEnquiry(service.id)} dangerouslySetInnerHTML={{ __html: service.shortdescription.slice(0, 10) + "..." }}  />
                  <div className='d-flex  align-items-center w-100 justify-content-center'>
                    <Button className='btn btnOrange' onClick={handleShow} >Enquiry Now</Button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </div>
  </Container>
</section>
<Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Inquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control

                  type="number"
                  placeholder="Phone Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Modal>
    </>
  );
};

export default Services;
