import image1 from '../../../../Images/how-1.png';
import image2 from '../../../../Images/how-2.png';
import image3 from '../../../../Images/how-3.png';
import image4 from '../../../../Images/how-4.png';

export const HOWWORK=[
    {
        image:image1,
        title:'Book a consultation',
    },
    {
        image:image2,
        title:'Wide Assortments',
    },
    {
        image:image3,
        title:'On Time Delivery',
    },
    {
        image:image4,
        title:'Assured Quality',
    },
]