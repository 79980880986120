import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import style from './Banner.module.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';

const Banner = () => {
  const [bannerList, setBannerList] = useState([]);

  useEffect(() => {
    fetchBannerData();
  }, []);

  const fetchBannerData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/home`);
      setBannerList(response.data.ResponseData.bannerList);
    } catch (error) {
      console.error('Error fetching banner data:', error);
    }
  };

  return (
    <>
      <div className={style.banner}>
        <Carousel>
          {bannerList.map((banner) => (
            <Carousel.Item key={banner.id}>
              <div className={style.bannerFlex}>
                <img src={banner.image} alt="" className='img-fluid' />
              </div>
              <Carousel.Caption>
                <div className='title'>
                <div className='heading'>
                  <h3 className='mb-3'>{banner.title}</h3>
                  <Button className='btn btnOrange'>Enquiry Now</Button>
                </div>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
    </>
  );
};

export default Banner;
