import React, { useState, useEffect } from 'react';
import Header from '../../Common/Header/Header';
import { Container, Col, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import style from './Gallery.module.css';
import { Carousel } from 'react-bootstrap';

const Gallery = ({ name }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [subImages, setSubImages] = useState([]);
  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    fetchGalleryList();
  }, []);

  const fetchGalleryList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/home`);
      setGalleryList(response.data.ResponseData.galleryList);
    } catch (error) {
      console.error('Error fetching gallery list:', error);
    }
  };

  const handleImageClick = async (imageId, imageUrl) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/album-wise-image/${imageId}`);
      const { ResponseData } = response.data;
      setSubImages(ResponseData);
      setSelectedImage(imageUrl);
      setShowModal(true);
    } catch (error) {
      console.error('Error fetching sub-images:', error);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedImage(null);
    setSubImages([]);
  };

  return (
    <>
      <Header name={"Gallery"} />

      <section id='gallery'>
        <Container fluid className='pe-xxl-5 ps-xxl-5'>
          <div className='mt-100 '>
            <div>
              <Row>
                {galleryList.map((item) => (
                  <Col key={item.id} xs={6} md={4} lg={4} xl={3} xxl={3} className="d-flex flex-column align-items-center">
                    <div className={style.galleryFlex} onClick={() => handleImageClick(item.id, item.imageurl)}>
                      <img
                        src={item.imageurl}
                        alt=""
                        className='rounded-4 img-fluid gallery'
                        style={{ width: '100%', height: '100%' }}
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
          <Modal show={showModal} onHide={handleCloseModal} size="lg">
            <Modal.Body>
              <Carousel>
                {subImages.map((image) => (
                  <Carousel.Item key={image.id}>
                    <img src={image.image_url} alt="" className="d-block img-fluid" style={{ width: '100%', height: '400px', objectFit: 'fill' }} />
                  </Carousel.Item>
                ))}
              </Carousel>
              <div className="row mt-3">
                {subImages.map((image) => (
                  <div key={image.id} className="col-2">
                    <img
                      src={image.image_url}
                      alt=""
                      style={{ cursor: 'pointer', width: '110px', height: '110px', objectFit: 'cover', borderRadius: '5px' }}
                      onClick={() => setSelectedImage(image)}
                    />
                  </div>
                ))}
              </div>
            </Modal.Body>
          </Modal>
        </Container>
      </section>
    </>
  );
};

export default Gallery;
