import image1 from '../../../../Images/shield.png';
import image2 from '../../../../Images/settings.png';
import image3 from '../../../../Images/triangular-arrows-sign-for-recycle.png';
import image4 from '../../../../Images/virus.png';
import image5 from '../../../../Images/black-opened-umbrella-symbol-with-rain-drops-falling-on-it.png';
import image6 from '../../../../Images/no-bugs.png';

export const ADAVANTAGES=[
    {
        image:image1,
        titile:'Lifetime Durability',
        discription:'Each one of our state-of-the-art modular kitchens comes with a warranty of quality and excellence.',
    },
    {
        image:image2,
        titile:'100% Waterproof',
        discription:'100% waterproof material protects your kitchen against wet, humid & dusty climates.',
    },
    {
        image:image3,
        titile:'Termite & Rust-Proof',
        discription:'A wide range of rust proof, termite proof galvanized steel modular kitchens and accessories.',
    },
    {
        image:image4,
        titile:'100% Fungus Proof',
        discription:'Preventing that horrible build up of mould and mildew from excessive moisture due to damp conditions.',
    },
    {
        image:image5,
        titile:'Easy Maintenance',
        discription:'Modular kitchens are basically low maintenance or in some cases maintenance do not need a lot of time.',
    },
    {
        image:image6,
        titile:'100% Recyclable',
        discription:'Modular kitchens have high durability, are hygienic, easy to clean, eco-friendly and 100% recyclable.',
    },
]