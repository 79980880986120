import location from '../../../../Images/location.png'
import clock from '../../../../Images/clock.png'
import email from '../../../../Images/email.png'
import phone from '../../../../Images/telephone-call.png'

export const CONTACTUS=[
    {
        image:location,
        title:'Our Office Address',
        discription:'area, city, landmark, pincode',
    },
    {
        image:email,
        title:'General Enquiries',
        discription:'samplee@exapmplee.ecom',
    },
    {
        image:phone,
        title:'Call Us',
        discription:'+91 123 456 7890',
    },
    {
        image:clock,
        title:'Our Timing',
        discription:'Mon-Sun:10:00 AM-07:00 PM',
    }
]