import React, { useState, useEffect } from 'react';
import { Container, AppBar, Toolbar, Typography, Button, Box, CssBaseline, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { FaBars, FaTimes } from 'react-icons/fa';
import axios from 'axios';
import logo from '../../../Images/ecu_eyes_logo-removebg-preview (1).png';
import PropTypes from 'prop-types';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

const Topbar = (props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuList, setMenuList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleScroll = () => {
        const scrollTop = window.pageYOffset;

        if (scrollTop > 100) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const fetchMenuList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/home`);
        setMenuList(response.data.ResponseData.menuList);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    fetchMenuList();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo} alt="" className='logoToggle' />
      </Typography>
      <Divider />
      <List>
        {menuList.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.menu_name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Container fluid>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          component="nav"
          position="fixed"
          sx={{
            backgroundColor: isScrolled ? 'rgba(0, 0, 0, 0.7)' : '#42484d42',
            transition: 'background-color 0.3s ease',
            boxShadow: 'none',
          }}
        >
          <Toolbar sx={{ justifyContent: 'space-between' }}>
      
          <Link to="/">
              <Typography variant="h6" component="div">
                <img src={logo} alt="" className='logo' />
              </Typography>
            </Link>      
            <Box className='Detailmanu ' >
              {menuList.length === 0 ? (
                <Typography variant="body1">No data found</Typography>
              ) : (
                menuList.map((item) => (
                  <Button
                    key={item.id}
                    sx={{ color: '#fff' }}
                    onClick={() => navigate(`/ourservice/${item.id}`)}
                  >
                    {item.menu_name}
                  </Button>
                ))
              )}
              <Button onClick={() => navigate('/about')} sx={{ color: '#fff' }}>About</Button>
              <Button onClick={() => navigate('/gallery')} sx={{ color: '#fff' }}>Gallery</Button>
              <Button onClick={() => navigate('/contact')} sx={{ color: '#fff' }}>Contact</Button>
            </Box>
       
            <Box className='logo_menu'>
              <div>
                <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography variant="h6" component="div">
                    <img src={logo} alt="" className='logoDisplay' />
                  </Typography>
                </Link>
              </div>
              <div>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  edge="end"
                // sx={{ display: { sm: 'none'  } }}
                >
                  <MenuIcon className='menuLogo' />
                </IconButton>
              </div>
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { md: 'block', lg: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
    </Container>
  );
};

Topbar.propTypes = {
  window: PropTypes.func,
};

export default Topbar;
