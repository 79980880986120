import React from 'react'
import { ADAVANTAGES } from './Advantages.utils'
import { Button, Col, Container, Row } from 'react-bootstrap'

const Advantages = () => {
  return (
    <>
    <section id='advantages'>
      <Container fluid className='pe-5 ps-5'>
        <div className='mt-100 '>
          <h1 className='text-center mb-5 TitleColor'>Advantages</h1>
          <div>
            <Row>
              {
                ADAVANTAGES.map((ele) => (
                  <Col sm={12} md={6} lg={4}  className="d-flex flex-column align-items-center mb-5 ">
                    <div className='d-flex gap-3 justify-content-center align-items-center '>
                      <div>
                        <img src={ele.image} alt="" style={{ width: '60px', height: 'auto' }} />
                      </div>
                      <div>
                        <h4 className='mb-2 '>{ele.titile}</h4>
                        <p>{ele.discription}</p>
                      </div>
                    </div>
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
      </Container>
</section>
    </>
  )
}

export default Advantages
