import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const [menuList, setMenuList] = useState([]);
  const [websiteInfo, setWebsiteInfo] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {

        const homeResponse = await axios.get(`${process.env.REACT_APP_API}/home`);
        setMenuList(homeResponse.data.ResponseData.menuList);
        setWebsiteInfo(homeResponse.data.ResponseData.websiteinfo[0]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  console.log('Menu List:', menuList);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>

            {menuList && menuList.length > 0 && (
            <ul className='d-flex flex-row gap-3 mt-3 '>
            {menuList.slice(0, 12).map((menuItem) => (
              <li key={menuItem.id} style={{ cursor: 'pointer', textWrap: 'nowrap' }} onClick={() => navigate(`/ourservice/${menuItem.id}`)}>
                {menuItem.menu_name}
              </li>
            ))}
            {menuList.length > 12 && <li style={{ cursor: 'pointer', textWrap: 'nowrap' }}>&hellip;</li>}
          </ul>
            )}
          </Col>
          <Col>
            <div className='d-flex flex-column align-items-end mt-3 mb-3 '>
              <div className='d-flex gap-2 '>
                {/* Render social media icons for Contact section */}
                {websiteInfo.fb_url && (
                  <a href={websiteInfo.fb_url} className='text-dark' target="_blank" rel="noopener noreferrer">
                    <i class="bi bi-facebook"></i>
                  </a>
                )}
                {websiteInfo.twitter_url && (
                  <a href={websiteInfo.twitter_url} className='text-dark' target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-twitter"></i>
                  </a>
                )}
                {websiteInfo.instagram_url && (
                  <a href={websiteInfo.instagram_url} className='text-dark' target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-instagram"></i>
                  </a>
                )}
                {websiteInfo.pinterest_url && (
                  <a href={websiteInfo.pinterest_url} className='text-dark' target="_blank" rel="noopener noreferrer">
                    <i className="bi bi-pinterest"></i>
                  </a>
                )}
              </div>
              {/* Render phone number and email for Contact section */}
              {websiteInfo.phone_number1 && <span>{websiteInfo.phone_number1}</span>}
              {websiteInfo.email_id1 && <span>{websiteInfo.email_id1}</span>}
              <span>© Copyrights 2023.Kitchen Palace.All Rights Reserved.</span>
            </div>
          </Col>
          <div className='bg-warning  p-2' >
            <span className='ps-3'>CHANGE LANGUAGE :English Hindi Gujrati Panjabi</span>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default Footer
