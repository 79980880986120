import React, { useState, useEffect } from 'react'
import Header from '../../Common/Header/Header'
import { Container, Col, Row, Button } from 'react-bootstrap';
import axios from 'axios';
import image1 from '../../../Images/8632.jpg';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import style from '../Home/Services/Services.module.css';
import { SERVICES } from '../OurService/Ourservice.utils';
import { useParams } from 'react-router-dom';
import {  Typography } from '@mui/material';

const OurService = () => {
  const [services, setServices] = useState([]);
  const { menuId } = useParams();
  const [show, setShow] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    message: '',
    mobile: '',
    product_id: 1,
    client_id: 1,
    title: '',


  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/screen/${menuId}`);
        setServices(response.data.ResponseData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [menuId]);
 

  if (services.length === 0) {
    return (
         <>
          <Header name={"Our Service"}  />
      <Container fluid>
          <div className='mt-100 d-flex justify-content-center '>           
           <img src={image1} alt=""  className='rounded-4 img-fluid'  style={{width:"500px" , height:"400px"}}/>
            </div>
            </Container>
         </>
    
    );
  }


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleEnquiry = (id) => {
    // Redirect to the enquiry page with the productId
    window.location.href = `/enquirypage/${id}`;
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async () => {
    setSaving(true);
    if (!saving) {
      handleClose();
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/sendinquiry`, {
          email_id: formData.email,
          mobile: formData.mobile,
          dissociation: formData.message,
          product_id: formData.product_id,
          client_id: formData.client_id,
          title: formData.title,
        });


        console.log('Response:', response.data);


        handleClose();
        setFormData({
          email: '',
          message: '',
          mobile: '',
          product_id: 1,
          client_id: 1,
          title: '',
        });
      } catch (error) {
        console.error('Error:', error);

      } finally {
        setSaving(false);
      }
    }
  };
  console.log(formData);

  return (
    <>
      <Header name={"Our Service"} subname={"Privacy Policy"} />
      <Container fluid>
        <section id='services'>
          <Container fluid className='pe-xxl-5 ps-xxl-5'  >
            <div className='mt-5 '>

              <Row>
                {services.map((service) => (
                  <Col md={6} lg={4} xl={2} xxl={3} xs={12} key={service.id}>
                    <div className={style.serviceBox} >
                      <div className={style.serviceImage}>
                        <img src={service.image} alt="" onClick={() => handleEnquiry(service.id)} className=' rounded-2 ' />
                      </div>                                  
                        <div  dangerouslySetInnerHTML={{ __html: service.shortdescription.slice(0, 45) }} />
                        {service.shortdescription.length > 45 && '...'}                     
                        <p onClick={() => handleEnquiry(service.id)} style={{ marginBottom: '0px' }}>
                          Price: {service.price}
                          <span style={{ textDecoration: 'line-through', marginLeft: '5px' }}>{service.discount_price}</span>
                        </p>
                                          <div className={style.servicesbtn}>
                        <Button className='btn btnOrange ' onClick={handleShow}>Enquiry Now</Button>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </Container>
        </section>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Inquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control

                  type="number"
                  placeholder="Phone Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>

    </>
  )
}

export default OurService
