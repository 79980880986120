import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {  Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EnquiryPage = () => {
    const [serviceDetails, setServiceDetails] = useState(null);
    const [loading, setLoading] = useState(true);   
    const { id } = useParams(); 
    const [formData, setFormData] = useState({
        email: '',
        message: '',
        mobile: '',
        product_id: 1,
        client_id: 1,
        title: '',
    
    
      });

      const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const response = await axios.post(`${process.env.REACT_APP_API}/sendinquiry`, formData);
          console.log('Response:', response.data);
          toast.success('Form submitted successfully!');
          setFormData({
            email: '',
            message: '',
            mobile: '',
            product_id: 1,
            client_id: 1,
            title: '',
          });
        } catch (error) {
          console.error('Error submitting form:', error);
        }
      };
    

    useEffect(() => {
        const fetchServiceDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/screen-details/${id}`);
                setServiceDetails(response.data.ResponseData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching service details:', error);
                setLoading(false);
            }
        };

        fetchServiceDetails();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!serviceDetails) {
        return <p>Error fetching service details</p>;
    }

    const { servicedetails } = serviceDetails;
    return (
        <>
            <div className='mt-100 pt-5'>
                <div className='mt-5 mb-5'>
                    <Container fluid className='pe-xxl-5 ps-xxl-5'>
                        <Row>
                            <Col lg={9} md={12} sm={12} >
                                <Row className=' d-flex  justify-content-between ' >
                                    <Col lg={4} md={12} sm={12}  >
                                        <div className=''>
                                            <img src={servicedetails[0].image} alt="" style={{ width: '550px', height: '400px' }}  />
                                        </div>
                                    </Col>
                                    <Col lg={7} md={12} sm={12}>
                                        <div>
                                        <h4 className='mb-3'>{servicedetails[0].service_name}</h4>
                                        <span >Price:- {servicedetails[0].price}
                                        <span className='text-decoration-line-through ms-2'>{servicedetails[0].discount_price}</span></span>
                                            <div className='mt-3' dangerouslySetInnerHTML={{ __html: servicedetails[0].shortdescription }} />
                                        </div>
                                       
                                    </Col>
                                    <div className='mt-5' dangerouslySetInnerHTML={{ __html: servicedetails[0].description }} style={{ width: '100%', height: 'auto' }} />
                                </Row>
                            </Col>
                            <Col lg={3} md={12} sm={12}>
                                {/* <div className='border rounded w-100 h-100'>

                                </div> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8} md={12} sm={12} >
                                <Tabs
                                    defaultActiveKey="profile"
                                    id="fill-tab-example"
                                    className="mb-3  tabs-container"
                                    fill
                                >
                                    <Tab eventKey="home" title="Brochure Download">
                                        Tab content for Brochure Download
                                    </Tab>
                                  
                                    <Tab eventKey="longer-tab" title="Product Enquiry">
                                    <Form className='w-50' onSubmit={handleSubmit}  validate>
                                <input
                                    type="text"
                                    placeholder='Your Name'
                                    className='mb-3 w-100 p-2'
                                    name="name"
                                    value={formData.name}
        onChange={handleInputChange}

                                   
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder='Your Title'
                                    className='mb-3 w-100 p-2'
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    required
                                />
                                <input
                                    type="tel"

                                    placeholder='Your Contact (Format:+91 1234567890)'
                                    className='mb-3 w-100 p-2'
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleInputChange}
                                    required
                                />
                                <textarea
                                    id="w3review"
                                    name="description"
                                    rows="4"
                                    cols="50"
                                    className='mb-3 w-100 p-2'
                                    placeholder='Description'
                                    value={formData.description}
        onChange={handleInputChange}
                                />
                                <Button type="submit" className='btn btnOrange w-25 mb-5'>Submit</Button>
                            </Form>
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <hr />
        </>
    )
}

export default EnquiryPage
