import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import map from '../../../Images/google-map3-1080x630.jpg';
import axios from 'axios';
import { CONTACTUS } from '../Home/ContactUs/ContactUs.utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../../Common/Header/Header';

const ContactUs = ({ name }) => {
    const [websiteInfo, setWebsiteInfo] = useState({});
    const [formData, setFormData] = useState({
        name: '',
        title: '',
        mobile: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/add-contract`, formData);
            console.log('Response:', response.data);
            toast.success('Form submitted successfully!');
            setFormData({
                name: '',
                title: '',
                mobile: '',
                description: '',
            });
            // Handle success response here
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred. Please try again later.');
            // Handle error response here
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API}/home`);
                setWebsiteInfo(response.data.ResponseData.websiteinfo[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>

          <Header name={"Contact Us"} />

            <Container fluid className='pe-5 ps-5 d-flex flex-column align-items-center'>
                <div className='mt-100 '>
                    {/* <h1 className='text-center mb-5 TitleColor'>Contact Us</h1> */}
                    <Row>
                        <Col sm={12} lg={5} className="">
                        <Form className='w-100'>
                                <input type="text" placeholder='Your Name' className='mb-3 w-100 p-2' name="name" value={formData.name} onChange={handleChange} />
                                <input type="text" placeholder='Your Title' className='mb-3 w-100 p-2' name="title" value={formData.title} onChange={handleChange} />
                                <input type="number" placeholder='Your Contact' className='mb-3 w-100 p-2' name="mobile" value={formData.mobile} onChange={handleChange} />
                                <textarea id="w3review" name="description" rows="4" cols="50" className='mb-3 w-100 p-2' placeholder='Description' value={formData.description} onChange={handleChange} />
                                <Button className='btn btnOrange w-25 mb-5' onClick={handleSubmit}>Submit</Button>
                            </Form>
                        </Col>
                        <Col sm={12} md={12} lg={1} className="SecondCol">
                        </Col>
                        <Col sm={12} md={12} lg={5} className="d-flex flex-column gap-3 Iconcontact">
                            {CONTACTUS.map((ele) => (
                                <div key={ele.title} className='d-flex align-items-center justify-content-start  gap-5 '>
                                    <img src={ele.image} alt="" style={{ width: '40px' }} className='ContactIcon' />
                                    <div>
                                        <h6>{ele.title}</h6>
                                        <p className='fs-5 paraContact'>
                                            {ele.title === 'Our Office Address'
                                                ? websiteInfo.address
                                                : ele.title === 'General Enquiries'
                                                    ? websiteInfo.email_id1
                                                    : ele.title === 'Call Us'
                                                        ? websiteInfo.phone_number1
                                                        : ele.title === 'Our Timing'
                                                            ? websiteInfo.office_time
                                                            : ''}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </Col>


                    </Row>
                </div>
                <ToastContainer />
            </Container>
        </>
    )
}

export default ContactUs
