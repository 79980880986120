import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

const Category = () => {
  const [servicesWithProducts, setServicesWithProducts] = useState([]);
  const [saving, setSaving] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    message: '',
    mobile: '',
    product_id: 1,
    client_id: 1,
    title: '',


  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${process.env.REACT_APP_API}/home`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        // Filter services with non-empty 'menu_product' array
        const filteredServices = data.ResponseData.serviceList.filter(service => service.menu_product.length > 0);
        setServicesWithProducts(filteredServices);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setSaving(true);
    if (!saving) {
      handleClose();
    } else {
      try {
        const response = await axios.post(`${process.env.REACT_APP_API}/sendinquiry`, {
          email_id: formData.email,
          mobile: formData.mobile,
          dissociation: formData.message,
          product_id: formData.product_id,
          client_id: formData.client_id,
          title: formData.title,
        });


        console.log('Response:', response.data);


        handleClose();
        setFormData({
          email: '',
          message: '',
          mobile: '',
          product_id: 1,
          client_id: 1,
          title: '',
        });
      } catch (error) {
        console.error('Error:', error);

      } finally {
        setSaving(false);
      }
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <section id='category'>
        <Container fluid  className='pe-xxl-5 ps-xxl-5'>
          <div className='mt-100 '>
            <h1 className='text-center mb-5 TitleColor'>Category</h1>
            <div>
              <Row>
                {
                  servicesWithProducts.map((service) => (
                    <Col xs={6} md={4} lg={2} className="d-flex flex-column align-items-center gap-3" key={service.id}>
                      <img src={service.image} alt="" className='img-fluid' style={{ maxWidth: '100%', height: 'auto' }} />
                      <h5 className='text-center mb-4 ProductName'>{service.name}</h5>
                      <Button className='btn btnOrange' onClick={handleShow}>Enquiry Now</Button>
                    </Col>
                  ))
                }
              </Row>
              {/* <Button className='btn btnOrange' onClick={() => setVisibleCategories(CATEGORY.length)}>View All</Button> */}
           
            </div>
          </div>
        </Container>
      </section>
      <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Inquiry</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control

                  type="number"
                  placeholder="Phone Number"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  autoFocus
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSubmit}>
              {saving ? 'Saving...' : 'Save Changes'}
            </Button>
          </Modal.Footer>
        </Modal>
    </> 
  );
 
};

export default Category ;


