import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ReactPlayer from 'react-player';

const Videos = () => {
  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    const fetchVideoList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/home`);
        setVideoList(response.data.ResponseData.videoList);
      } catch (error) {
        console.error('Error fetching video list:', error);
      }
    };

    fetchVideoList();
  }, []);
  

  return (
    <section id='videos'>
      <Container fluid className='pe-5 ps-5'  >
        <div className='mt-100 '>
          <h1 className='text-center mb-5 TitleColor'>Videos</h1>
          <div>
            <Row className='gy-5'>
              {videoList.map((video) => (
                <Col xs={12} md={6} lg={6} xl={6} xxl={6} key={video.id} className='d-flex justify-content-center align-items-center flex-column'>
                  <ReactPlayer
                    url={video.video_url}
                    controls
                    width='100%'
                    height='100% ' 
                    />
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Videos;
