import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import about from '../../../../Images/about.webp'

const About = () => {
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API}/home`);
        const aboutusData = response.data.ResponseData.aboutus[0];
        setAboutData(aboutusData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <section id='about'>
      <Container fluid className='pe-5 ps-5'>
        <div className='mt-100 '>
          <Row>
            <Col sm={12} md={12} lg={12} xl={6} className="d-flex flex-column align-items-center about1">
              <div className='about'>
                <h3 className='text-center mb-3 abouthead'>About Us</h3>
                <p
                  className='text-center fs-5 para'
                  dangerouslySetInnerHTML={{ __html: aboutData.shotdescription }}
                />
                 
              </div>
            </Col>
            <Col sm={12} md={12} lg={12} xl={6} className="d-flex flex-column align-items-center">
              <img src={about} alt="" className='img-fluid rounded-4 AboutImage' />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default About;
