import React from 'react';
import style from './Header.module.css';
import { Topbar } from '../../Layout/Topbar';

const Header = ({name}) => {
  return (
  <>  
 
    <div className={style.headerbox}>
    <div className={style.overlay}>
       <h1>{name}</h1>     
       </div>
   </div>
  </>
  )
}

export default Header
