import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import style from './Gallery.module.css';
const Gallery = () => {
  const [galleryList, setGalleryList] = useState([]);

  useEffect(() => {
    fetchGalleryList();
  }, []);

  const fetchGalleryList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}/home`);
      setGalleryList(response.data.ResponseData.galleryList);
    } catch (error) {
      console.error('Error fetching gallery list:', error);
    }
  };

  return (
    <>
      <section id='gallery'>
        <Container fluid  className='pe-xxl-5 ps-xxl-5' >
          <div className='mt-100 '>
            <h1 className='text-center mb-5 TitleColor'>Gallery</h1>
            <div>
              <Row>
                {galleryList.map((item) => (
                  <Col key={item.id} xs={6} md={4} lg={4} xl={3} xxl={3} className="d-flex flex-column align-items-center">
                   <div className={style.galleryFlex}>
                   <img
                      src={item.imageurl}
                      alt=""
                      className='rounded-4 img-fluid gallery'
                      style={{ width: '100%', height: '100%' }}
                    />
                   </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Gallery;
