import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './Components/Pages/Home/Home';
import { Topbar } from './Components/Layout/Topbar';
import Footer from './Components/Layout/Footer/Footer';
import EnquiryPage from './Components/Pages/EnquiryPage/EnquiryPage';
import OurService from './Components/Pages/OurService/OurService';
import ContactUs from './Components/Pages/ContactUs/ContactUs';
import About from './Components/Pages/About/About';
import Gallery from './Components/Pages/Gallery/Gallery';

function App() {
  return (
 
 <>
<Topbar/>
 <Routes>
 <Route path="*" element={<Home/>} />
 <Route path="/enquirypage/:id" element={<EnquiryPage/>} />
 <Route path="/ourservice/:menuId" element={<OurService/>} />
 <Route path="/contact" element={<ContactUs/>} />
 <Route path="/about" element={<About/>} />
 <Route path="/gallery" element={<Gallery/>} />
 </Routes>
 <Footer/>
 </>
  );
}

export default App;
