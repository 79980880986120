import React from 'react';
import { Banner } from './Banner';
import { Category } from './Category';
import Services from './Services/Services';
import Advantages from './Advantages/Advantages';
import Products from './Products/Products';
import HowWork from './HowWork/HowWork';
import About from './About/About';
import Gallery from './Gallery/Gallery';
import Videos from './Videos/Videos';
import ContactUs from './ContactUs/ContactUs';
import Tesimonial from './Tesimonial/Tesimonial';

const Home = () => {
  return (
    <>
      <Banner />
      <Category />
      {/* <Products /> */}
      <Services />
      <Advantages />
      <HowWork />
      <About />
      <Gallery />
      <Videos />
      <Tesimonial />
      <ContactUs />
    </>
  )
}

export default Home
