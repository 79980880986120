import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { HOWWORK } from './HowWork.utils'

const HowWork = () => {
  return (
   <>
   <section id='howWork'>
     <Container fluid className='pe-5 ps-5'>
        <div className='mt-100 '> 
          <h1 className='text-center mb-5 TitleColor'>How We Work</h1>
          <div>
            <Row>
              {
                HOWWORK.map((ele) => (
                  <Col sm={6} md={6} lg={3} className="d-flex flex-column align-items-center gap-2 mb-5 howFlex">
                    <img src={ele.image}  alt="" style={{ width: '60px', height: 'auto' }} />
                    <h4 className='howTitle'>{ele.title}</h4>
                  </Col>
                ))
              }
            </Row>
          </div>
        </div>
      </Container>
      </section>
    </>
  )
}

export default HowWork
